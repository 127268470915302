import { Link, graphql } from 'gatsby';
import { formatPostDate, formatReadingTime } from '../utils/helpers';
import Image from "gatsby-image";
import Layout from '../components/Layout';
import React from 'react';
import SEO from '../components/SEO';
import get from 'lodash/get';

class BlogIndexTemplate extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title');
    const langKey = this.props.pageContext.langKey;
    const posts = get(this, 'props.data.allMarkdownRemark.edges');

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO 
          title='Geothermal Heat Pump Project'
        />
          <section className="items-grid">
            {posts.map(({ node }) => {
              const title = get(node, 'frontmatter.title') || node.fields.slug;
              return (
                <article 
                className="single-item"
                key={node.fields.slug}
               >
                  <header>
                    <h2
                      style={{
                        fontFamily: 'Open Sans Condensed, sans-serif',
                        marginTop: '0',
                        marginBottom: '0',
                        fontWeight: '700'
                      }}
                    >
                      <Link
                        style={{ boxShadow: 'none' }}
                        to={node.fields.slug}
                        rel="bookmark"
                      >
                        {title}
                      </Link>
                    </h2>
                    <small style={{
                      marginBottom: '5px',
                      marginTop: '5px',
                      display: 'block'
                    }}>
                      {formatPostDate(node.frontmatter.date, langKey)}
                      {` • ${formatReadingTime(node.timeToRead)}`}
                    </small>
                  </header>
                  <Link className='ugly-link' aria-label="post-link" style={{marginBottom: '5px', display: 'block'}} to={node.fields.slug}>
                    <Image durationFadeIn={100} fluid={node.frontmatter.featuredImage.childImageSharp.fluid}/>
                  </Link>
                  <p style={{
                      lineHeight: '1.5',
                      marginBottom: '0',
                      fontFamily: 'Open Sans, sans-serif'
                    }}
                    dangerouslySetInnerHTML={{ __html: node.frontmatter.spoiler }}
                  />
                </article>
              );
            })}
          </section>
      </Layout>
    );
  }
}

export default BlogIndexTemplate;

export const pageQuery = graphql`
  query($langKey: String!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(
      filter: { fields: { langKey: { eq: $langKey } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
            langKey
          }
          timeToRead
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            spoiler
            videoSrcURL
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 510, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`;
